import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import CtaArea from "@containers/cta/layout-03";
import FunFactArea from "@containers/funfact/layout-04";
import BoxSection from "@components/BoxSection/layout-one/layout-3";
import TestimonialArea from "@containers/testimonial/layout-01";
import CompanyReviews from "@containers/global/company-reviews";
import Memberships from "@containers/global/memberships";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const AboutPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo title="About us - Global Translation Agency for Premium Campaigns"
        description="Andovar offers innovative language solutions for all your localization needs, empowering growth without the growing pains."
      />

      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["page-header-section"]} />
        <PartnerArea data={content["partner-section"]} />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
          <IntroArea data={content["aboutus-intro"]} /> 
          </>
        }
        {
          showRestComponents && <>
            <FunFactArea data={content["funfact-section"]} />
            <BoxSection data={content["about-whatwedo"]} />
            <TestimonialArea layout={1} data={content["testimonial-section"]} />
            <CompanyReviews layout={2} data={content["companyReviewsData"]} />
            <Memberships data={content["about-memberships"]} />
            <CtaArea data={content["cta-data"]} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query AboutPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "about-us" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

AboutPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default AboutPage;
